<template>
  <page-layout>
    <div
      class="wrapper dashboard"
      style="width:96% !important"
    >
      <div class="title-wrap">
        <h3 class="page-titles">
          Low Score Candidates
        </h3>
        <div
          v-if="isDownloading"
          class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-80"
        >
          <button class=" btn btn-lg btn-circle btn-ghost loading" />
        </div>
        <!-- <p class="">
          Manage your customers
        </p> -->
      </div>
      <div class="flex items-center">
        <div class="flex flex-row items-center">
          <label class="text-base font-semibold"> From </label>
          <div class="relative mx-4">
            <input
              type="date"
              id="fromDate"
              name="fromDate"
              class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
              v-model="fromDate"
              :max="maxToDate"
            >
          </div>
        </div>
        <div class="flex flex-row items-center ml-8">
          <label class="text-base font-semibold"> To </label>
          <div class="relative mx-4">
            <input
              type="date"
              id="toDate"
              name="toDate"
              class="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-md bg-gray-50 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
              v-model="toDate"
              :max="maxToDate"
            >
          </div>
        </div>
        <button
          @click="filtration()"
          class="text-white btn-primary-small   mx-2"
        >
          Search
        </button>
        <button
          @click="clearFiltration()"
          class="text-white btn-outline-small"
        >
          Reset
        </button>
      </div>
      <table class="table mt-4 overflow-hidden table-compact table-zebra w-full">
        <thead>
          <tr>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Date </dropdown-table-header>
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Candidate Name </dropdown-table-header>
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Score </dropdown-table-header>
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Organization Name </dropdown-table-header>
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Campaign Name </dropdown-table-header>
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Reports </dropdown-table-header>
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Comments </dropdown-table-header>
              </div>
            </th>
            <th>
              <div class="flex space-x-4">
                <dropdown-table-header> Test Issue </dropdown-table-header>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="showLoading || lowScoreCandidates.length <= 0">
            <td colspan="2">
              <div class="flex items-center justify-center w-full h-full">
                <button
                  v-if="showLoading"
                  class="btn btn-lg btn-circle btn-ghost loading"
                />
                <span v-if="lowScoreCandidates.length <= 0"> Can not found low score candidates </span>
              </div>
            </td>
          </tr>
          <tr
            v-for="candidate in lowScoreCandidates"
            :key="candidate.test_session_id + candidate.campaign_id + candidate.user_id + candidate.organisation_id"
          >
            <td class="text-base">
              <!-- {{ candidate.test_session_id }} -->
              {{ convertDay(candidate.created_at) }}
            </td>
            <td class="text-base">
              {{ candidate.user_name }}
            </td>
            <td class="text-base flex flex-col">
              <div
                class="badge badge-success my-2 rounded-none text-black"
                v-for="(item, index) in candidate.stanine_measure"
                :key="index"
              >
                {{ item }}
              </div>
            </td>
            <td class="text-base">
              {{ candidate.organisation_name }}
            </td>
            <td class="text-base">
              {{ candidate.campaign_name }}
            </td>
            <td
              class="text-base"
              v-if="!showLoading"
            >
              <button @click="openReports(candidate.organisation_id, candidate.campaign_id, candidate.user_id, 1)">
                <div
                  class="p-5 mx-2 badge badge-info rounded-none text-base"
                >
                  Full Cognitive
                </div>
              </button>
              <button @click="openReports(candidate.organisation_id, candidate.campaign_id, candidate.user_id, 2)">
                <div
                  class="p-5 mx-2 badge badge-primary rounded-none text-base"
                >
                  Summary Report
                </div>
              </button>
              <button @click="downloadRawDataReport(candidate.user_id, candidate.campaign_id)">
                <div
                  class="p-5 mx-2 badge badge-accent rounded-none text-base"
                >
                  Download Raw Data Report
                </div>
              </button>
            </td>
            <td
              class="text-base"
              v-if="!showLoading"
            >
              <span
                v-if="candidate.lowScoreCommentDetails !== undefined "
                @click="viewComments(candidate.lowScoreCommentDetails)"
                class="bg-green-100 cursor-pointer text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900 flex items-center justify-evenly"
              >Commented  <img
                class="w-6 h-6 mx-2"
                src="@/assets/capacio/eye-svgrepo-com.svg"
                tabindex="0"
                alt=""
              ></span>
              <span
                v-if="candidate.lowScoreCommentDetails === undefined "
                @click="selectedForComment(candidate)"
                class="bg-indigo-100 cursor-pointer text-indigo-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-indigo-200 dark:text-indigo-900 flex items-center justify-evenly"
              >Not Commented  <img
                class="w-6 h-6 mx-2"
                src="@/assets/capacio/comment.png"
                tabindex="0"
                alt=""
              > </span>
            </td>
            <td class="text-base">
              <span
                @click="openTestIssueModal(candidate.testIssue)"
                v-if="candidate.testIssue"
                class="bg-red-100 text-black-300 text-xs  mr-2 px-2.5 py-0.5  dark:bg-red-200 dark:text-red-900 cursor-pointer"
              >View issue</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-if="modal"
      >
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl">
              <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div class="flex items-start">
                  <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-gray-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="w-6 h-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Comment for low score candidate
                    </h3>
                    <div class="mt-2">
                      <p class="text-xs text-gray-500">
                        Last update  :- {{ convertDayAndTime(selectedComment.updatedAt) }}
                      </p>
                    </div>
                    <div class="mt-6">
                      <div class="form-control">
                        <label class="label">
                          <span class="text-sm text-gray-500 font-semibold"> Comment</span>
                        </label>

                        <textarea
                          id="message"
                          rows="8"
                          class="text-gray-700 border py-2 px-2 leading-tight text-sm focus:outline-none font-normal mt-6"
                          placeholder="Write your thoughts here..."
                          v-model="selectedComment.comment"
                        />
                        <label
                          v-if="errorMsg"
                          class="label"
                        >
                          <span class="label-text-alt text-red-800 text-sm">Please add comments</span>
                        </label>
                      </div>
                    </div>
                    <div class="flex flex-row justify-between">
                      <div class="py-4">
                        <div class="mt-8 flex items-center">
                          <p class="text-sm text-gray-500 font-semibold">
                            Commented By  :-
                          </p>
                          <p class="mx-2 text-xs">
                            {{ selectedComment.commented_by }}
                          </p>
                        </div>
                        <div class="mt-8  flex items-center">
                          <p class="text-sm text-gray-500 font-semibold">
                            Date  :-
                          </p>
                          <p class="mx-2 text-xs">
                            {{ convertDay(selectedComment.date) }}
                          </p>
                        </div>
                      </div>

                      <div
                        class="py-4"
                        v-if="selectedComment.edited"
                      >
                        <div class="mt-8  flex items-center">
                          <p class="text-sm text-gray-500 font-semibold">
                            Edited  :-
                          </p>
                          <p class="mx-2 text-xs">
                            <span class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900">True</span>
                          </p>
                        </div>
                        <div class="mt-8  flex items-center">
                          <p class="text-sm text-gray-500 font-semibold">
                            Edited By :-
                          </p>
                          <p class="mx-2 text-xs">
                            {{ selectedComment.edited_by }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="updateComment()"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-if="modalAdd"
      >
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl">
              <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div class="flex items-start">
                  <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-gray-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="w-6 h-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      class="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Comment for low score candidate
                    </h3>
                    <div class="mt-6">
                      <div class="form-control">
                        <label class="label">
                          <span class="text-sm text-gray-500 font-semibold"> Comment</span>
                        </label>

                        <textarea
                          id="message"
                          rows="8"
                          class="text-gray-700 border py-2 px-2 leading-tight text-sm focus:outline-none font-normal mt-6"
                          placeholder="Write your thoughts here..."
                          v-model="addedComment.comment"
                        />
                        <label
                          v-if="errorMsg"
                          class="label"
                        >
                          <span class="label-text-alt text-red-800 text-sm">Please add comments</span>
                        </label>
                      </div>
                    </div>
                    <div class="flex flex-row justify-between">
                      <div class="py-4">
                        <div class="mt-8 flex items-center">
                          <p class="text-sm text-gray-500 font-semibold">
                            Commented By  :-
                          </p>
                          <p class="mx-2 text-xs">
                            {{ $store.state.ADMIN.admin.account.username }}
                          </p>
                        </div>
                        <div class="mt-8  flex items-center">
                          <p class="text-sm text-gray-500 font-semibold">
                            Date  :-
                          </p>
                          <p class="mx-2 text-xs">
                            {{ convertDayAndTime(addedComment.date) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="closeModal()"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="saveComment()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="relative z-10"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        v-if="testIssuesModal"
      >
        <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <div class="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-4xl">
              <div class="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div class="flex items-start">
                  <div class="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-gray-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      class="w-6 h-6 text-green-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3
                      class="text-lg font-bold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      View test issues
                    </h3>
                    <div class="mt-6">
                      <div class="form-control">
                        <label class="label">
                          <!-- <span class="text-sm text-gray-500 "> {{ selectedIssue }}</span> -->
                        </label>
                        <ul
                          class="flex flex-col my-4"
                          v-for="issue in selectedIssue"
                          :key="issue.id"
                        >
                          <li class="text-base my-4 py-1">
                            <span class="font-medium"> Test :</span>  {{ issue.type }}
                          </li>
                          <li class="text-base my-4 py-1">
                            <span class="font-medium"> Disturbance : </span>
                            <span
                              v-if="issue.disturbance === true"
                              class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"
                            >True</span>
                            <span
                              v-if="issue.disturbance !== true"
                              class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"
                            >False</span>

                            <!-- {{ issue.disturbance }} -->
                          </li>
                          <li class="text-base my-4 py-1">
                            <span class="font-medium"> Unclear Instructions : </span>
                            <!-- {{ issue.unclearInstructions }} -->
                            <span
                              v-if="issue.unclearInstructions === true"
                              class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"
                            >True</span>
                            <span
                              v-if="issue.unclearInstructions !== true"
                              class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"
                            >False</span>
                          </li>
                          <li>
                            <span class="font-medium">  Technical Issues : </span>
                            <!-- {{ issue.unclearInstructions }} -->
                            <span
                              v-if="issue.technicalIssues === true"
                              class="bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-red-200 dark:text-red-900"
                            >True</span>
                            <span
                              v-if="issue.technicalIssues !== true"
                              class="bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900"
                            >False</span>
                          </li>
                          <li
                            class="text-base my-4 py-1"
                            v-if="issue.otherIssues !== null"
                          >
                            <span class="font-medium"> Other Issues :  </span> {{ issue.otherIssues }}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  class="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="closeTestIssueModal()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </page-layout>
</template>

<script>
import DropdownTableHeader from '../components/DropdownTableHeader.vue'
import PageLayout from '@/layouts/PageLayout.vue'
import JwtDecode from 'jwt-decode'
import dayjs from 'dayjs'
import download from 'downloadjs'
export default {
  components: {
    DropdownTableHeader,
    PageLayout
  },
  data () {
    return {
      lowScoreCandidates: [],
      lowScoreComments: [],
      testIssues: [],
      showLoading: false,
      selectedOrganizations: {},
      selectedLowScoreForComment: {},
      selectedIssue: {},
      modal: false,
      modalAdd: false,
      testIssuesModal: false,
      toDate: '',
      fromDate: '',
      maxToDate: dayjs(new Date()).format('YYYY-MM-DD'),
      selectedComment: {},
      errorMsg: false,
      addedComment: {
        comment: '',
        commented_by: '',
        date: new Date()
      },
      isDownloading: false
    }
  },
  async mounted () {
    this.showLoading = true
    await this.fetchLowScoreCandidates()
    await this.loadComments()
    await this.getAllTestIssuesFromBackend()
    await this.mapComments()
    this.showLoading = false
  },

  methods: {
    async fetchLowScoreCandidates () {
      const body = {}
      this.lowScoreCandidates = await this.$api.getLowScoreCandidates(body)
      this.lowScoreCandidates = this.lowScoreCandidates.map(function (element) {
        return {
          ...element,
          primaryKey: element.test_session_id + element.campaign_id + element.user_id + element.organisation_id
        }
      })
    },
    stayAsLogged () {
      this.modal = false
    },
    async openReports (orgId, campId, userid, reportType) {
      const res = await this.$api.loginToOrganizationAsAdmin(orgId)
      const decoded = JwtDecode(res.token)
      this.$store.commit('USER/SET_USER', {
        token: res.token,
        firstName: ' ',
        lastName: ' ',
        admin: decoded.admin,
        organizationsName: decoded.organisationName,
        organizationId: decoded.organisationId
      })
      if (reportType === 1) { // full cognitve report
        this.$router.push({ name: 'AppReport', params: { userId: userid }, query: { comparisonPage: true, campaignId: campId } })
      } else {
        this.$router.push({ name: 'SummaryReport', params: { userId: userid }, query: { comparisonPage: true, campaignId: campId } })
      }
    },
    convertDay (date) {
      return dayjs(date).format('YYYY-MM-DD')
    },
    convertDayAndTime (date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
    async filtration () {
      try {
        const body = {
          fromDate: `${this.fromDate} 00:00:00-06`,
          toDate: `${this.toDate} 00:00:00-06`
        }
        this.lowScoreCandidates = await this.$api.getLowScoreCandidates(body)
      } catch (err) {
        console.log('error', err)
      }
    },
    async clearFiltration () {
      this.toDate = ''
      this.fromDate = ''
      try {
        await this.fetchLowScoreCandidates()
      } catch (err) {
        console.log('error', err)
      }
    },
    async viewComments (comment) {
      try {
        this.modal = true
        this.selectedComment = comment
      } catch (err) {
        console.log('error', err)
      }
    },
    async loadComments () {
      try {
        this.lowScoreComments = await this.$api.getAllLowScoreComments()
        this.lowScoreComments = this.lowScoreComments.map(function (element) {
          return {
            ...element,
            primaryKey: element.test_session_id + element.campaign_id + element.user_id + element.organisation_id
          }
        })
      } catch (err) {
        console.log('error', err)
      }
    },
    async mapComments () {
      try {
        for (let index = 0; index < this.lowScoreComments.length; index++) {
          const d = this.lowScoreCandidates.findIndex(ele => ele.primaryKey === this.lowScoreComments[index].primaryKey)
          if (d !== undefined || d !== null) {
            this.lowScoreCandidates[d].lowScoreCommentDetails = this.lowScoreComments[index]
          }
        }
        for (let index = 0; index < this.testIssues.length; index++) {
          const d = this.lowScoreCandidates.findIndex(ele => ele.test_session_id === this.testIssues[index].testSessionId)
          if (d !== undefined || d !== null) {
            if (this.lowScoreCandidates[d].testIssue !== undefined) {
              this.lowScoreCandidates[d].testIssue.push(this.testIssues[index])
            } else {
              this.lowScoreCandidates[d].testIssue = [this.testIssues[index]]
            }
          }
        }
      } catch (err) {
        console.log('error', err)
      }
    },
    async selectedForComment (lowScore) {
      try {
        this.modalAdd = true
        this.selectedLowScoreForComment = lowScore
      } catch (err) {
        console.log('error', err)
      }
    },
    async  closeModal () {
      this.modalAdd = false
      this.modal = false
      this.addedComment = {
        comment: '',
        commented_by: '',
        date: new Date()
      }
    },
    async  saveComment () {
      if (this.addedComment.comment === undefined || this.addedComment.comment === null || this.addedComment.comment === '') {
        this.errorMsg = true
      } else {
        this.errorMsg = false
        const createComment = {
          user_id: this.selectedLowScoreForComment.user_id,
          date: (this.addedComment.date).toString(),
          campaign_id: this.selectedLowScoreForComment.campaign_id,
          comment: this.addedComment.comment,
          organisation_id: this.selectedLowScoreForComment.organisation_id,
          test_session_id: this.selectedLowScoreForComment.test_session_id,
          stanine_measure: JSON.stringify(this.selectedLowScoreForComment.stanine_measure),
          commented_by: this.$store.state.ADMIN.admin.account.username
        }
        await this.$api.createLowScoreComments(createComment)
        this.showLoading = true
        this.modalAdd = false
        this.modal = false
        this.addedComment = {
          comment: '',
          commented_by: '',
          date: new Date()
        }
        await this.fetchLowScoreCandidates()
        await this.loadComments()
        await this.mapComments()
        this.showLoading = false
      }
    },
    async  updateComment () {
      if (this.selectedComment.comment === undefined || this.selectedComment.comment === null || this.selectedComment.comment === '') {
        this.errorMsg = true
      } else {
        this.errorMsg = false
        const updateComment = {
          user_id: this.selectedComment.user_id,
          date: this.selectedComment.date,
          campaign_id: this.selectedComment.campaign_id,
          comment: this.selectedComment.comment,
          organisation_id: this.selectedComment.organisation_id,
          test_session_id: this.selectedComment.test_session_id,
          stanine_measure: JSON.stringify(this.selectedComment.stanine_measure),
          commented_by: this.selectedComment.commented_by,
          edited: true,
          edited_by: this.$store.state.ADMIN.admin.account.username
        }
        await this.$api.updateLowScoreComments(updateComment)
        this.selectedComment = {}
        this.showLoading = true
        this.modalAdd = false
        this.modal = false
        this.addedComment = {
          comment: '',
          commented_by: '',
          date: new Date()
        }
        await this.fetchLowScoreCandidates()
        await this.loadComments()
        await this.mapComments()
        this.showLoading = false
      }
    },
    async getAllTestIssuesFromBackend () {
      try {
        this.testIssues = await this.$api.getAllTestIssues()
      } catch (err) {
        console.log('error', err)
      }
    },
    async openTestIssueModal (issue) {
      this.testIssuesModal = true
      this.selectedIssue = issue
    },
    async closeTestIssueModal () {
      this.testIssuesModal = false
      this.selectedIssue = {}
    },
    async downloadRawDataReport (userId, campaignId) {
      this.isDownloading = true
      try {
        const { data, headers } = await this.$api.getRawDataReport(userId, campaignId)
        const contentType = headers['content-type']
        download(data, '', contentType)
        this.isDownloading = false
      } catch (error) {
        console.log(error)
        this.isDownloading = false
      }
    }

  }
}
</script>

<style>
</style>
